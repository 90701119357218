'use client';

import { MoonIcon } from '@heroicons/react/24/solid';
import { MinusSmallIcon, SunIcon } from '@heroicons/react/24/outline';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

export function ThemeSwitcher() {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted)
    return (
      <button>
        <MinusSmallIcon className="h-6 w-6" />
      </button>
    );

  return (
    <>
      {theme === 'light' ? (
        <button title="Switch to Dark" onClick={() => setTheme('dark')}>
          <SunIcon className="h-6 w-6" />
        </button>
      ) : (
        <button title="Switch to Light" onClick={() => setTheme('light')}>
          <MoonIcon className="h-6 w-6" />
        </button>
      )}
    </>
  );
}
