import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export default function useCookie(key: string, defaultValue: string) {
  const [state, setState] = useState(() => {
    const cookieValue = Cookies.get(key);
    if (cookieValue !== undefined) {
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        return defaultValue;
      }
    }
    return defaultValue;
  });

  useEffect(() => {
    Cookies.set(key, JSON.stringify(state), {
      path: '/',
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  }, [key, state]);

  return [state, setState];
}
