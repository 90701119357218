'use client';

import { usePathname, useRouter } from 'next/navigation';
import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarBrand,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from '@nextui-org/navbar';
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
} from '@nextui-org/dropdown';
import { Input } from '@nextui-org/input';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ThemeSwitcher } from '@/components/ThemeSwitcher';
import { useEffect, useState } from 'react';
import { Button } from '@nextui-org/button';
import { Select, SelectItem } from '@nextui-org/select';
import type { Selection } from '@nextui-org/table';
import useCookie from '@/components/cookieHook';
import { Cog6ToothIcon, MapPinIcon } from '@heroicons/react/24/solid';
import Link from 'next/link';
import type { Admin } from '@/types/adminUser';
import { DEBUG, PRODUCTION_HOST } from '@/lib/cfg';
import type { Community } from '@/types/community';
import Image from 'next/image';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Navbar({
  user,
  communityUUID,
  communities,
}: {
  user?: Admin;
  communityUUID: string;
  communities: Community[];
}) {
  const router = useRouter();
  const pathname = usePathname();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [_, setCommunity] = useCookie('selectedCommunity', '');
  const [selectedCommunity, setSelectedCommunity] = useState<string>();
  const [selectValue, setSelectValue] = useState<Selection>(new Set([communityUUID]));
  const [isProduction, setIsProduction] = useState<boolean | null>(null);

  useEffect(() => {
    setIsProduction(window?.location?.host === PRODUCTION_HOST);
  }, []);

  useEffect(() => {
    if (communityUUID) {
      setSelectValue(new Set([communityUUID]));
      setSelectedCommunity(communityUUID);
    }
  }, [communityUUID]);

  const navigation = [
    { name: 'Dashboard', tpl: '/community/:uuid/dashboard' },
    { name: 'People', tpl: '/community/:uuid/users' },
    // { name: 'Patrons', tpl: '/community/:uuid/patrons' },
    // { name: 'Funding', tpl: '/community/:uuid/funding' },
  ];

  const changeCommunity = (keys: Selection) => {
    setSelectValue(keys);
    const [firstItem] = keys;
    setSelectedCommunity(firstItem.valueOf().toString());
    setCommunity(firstItem.toString());

    const matchingNav = navigation.find((nav) =>
      pathname.includes(nav.tpl.split('/:uuid/')[1])
    );
    if (matchingNav) {
      router.push(matchingNav.tpl.replace(':uuid', firstItem.toString()));
    }
  };

  const doSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const query = e.currentTarget.value;
      const encodedQuery = encodeURIComponent(query);
      router.push(`/search?q=${encodedQuery}`);
      setIsMenuOpen(false);
    }
  };

  const logout = async () => {
    const resp = await fetch('/auth/logout', {
      method: 'DELETE',
    });

    if (!resp.ok) {
      DEBUG && console.error('Could not log out', resp);
      throw new Error('Error during logout');
    }
    router.refresh();
  };

  const searchInput = (
    <Input
      aria-label="Search"
      classNames={{
        input: 'text-sm',
      }}
      // endContent={
      //   <Kbd className="hidden lg:inline-block" keys={['command']}>
      //     K
      //   </Kbd>
      // }
      labelPlacement="outside"
      placeholder="Search..."
      variant="bordered"
      startContent={
        <MagnifyingGlassIcon className="h-6 w-6 text-base text-default-400 pointer-events-none flex-shrink-0" />
      }
      type="search"
      onKeyUp={doSearch}
    />
  );

  const settingsDropdown = (
    <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <Button isIconOnly variant="light">
          <Cog6ToothIcon className="h-6 w-6" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        disabledKeys={user?.role !== 'SUPER' ? ['adminusers'] : []}
        aria-label="Profile Actions"
        variant="flat"
      >
        <DropdownSection showDivider>
          <DropdownItem
            key="profile"
            className="gap-2"
            textValue="signed in as"
            onPress={() => router.push('/settings')}
          >
            <p className="font-semibold">Signed in as</p>
            <p className="font-semibold">{user?.name}</p>
            <p className="font-semibold">{user?.email}</p>
          </DropdownItem>
        </DropdownSection>

        <DropdownSection showDivider className={user?.role !== 'SUPER' ? 'hidden' : ''}>
          <DropdownItem key="adminusers" onPress={() => router.push('/admin/users')}>
            Admin Users
          </DropdownItem>
        </DropdownSection>

        <DropdownSection>
          <DropdownItem key="logout" color="danger" onPress={logout}>
            Log Out
          </DropdownItem>
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  );

  const communityDropdown = (
    <Select
      variant="bordered"
      size="sm"
      startContent={<MapPinIcon className="w-6 h-6" />}
      disallowEmptySelection={true}
      aria-label="community"
      placeholder="Select a community"
      selectedKeys={selectValue}
      className="max-w-xs"
      onSelectionChange={changeCommunity}
    >
      {communities.map((community) => (
        <SelectItem key={community.uuid} value={community.uuid}>
          {community.name}
        </SelectItem>
      ))}
    </Select>
  );

  return (
    <NextUINavbar
      maxWidth="2xl"
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      classNames={{
        item: [
          'flex',
          'relative',
          'h-full',
          'items-center',
          "data-[active=true]:after:content-['']",
          'data-[active=true]:after:absolute',
          'data-[active=true]:after:bottom-0',
          'data-[active=true]:after:left-0',
          'data-[active=true]:after:right-0',
          'data-[active=true]:after:h-[2px]',
          'data-[active=true]:after:rounded-[2px]',
          'data-[active=true]:after:bg-primary',
        ],
      }}
    >
      <NavbarContent justify="start">
        <NavbarMenuToggle
          className="md:hidden"
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        />
        <NavbarBrand>
          <Image
            alt="logo"
            className="inline-block"
            width={40}
            height={40}
            src="/images/UGS-Logo.png"
          ></Image>
          {isProduction !== null && (
            <div
              className={`${isProduction ? 'bg-green-400' : 'bg-yellow-400'} rounded-lg ml-3 my-3 p-2 font-bold text-sm md:text-lg`}
            >
              <div className="block sm:hidden">{isProduction ? 'PROD' : 'DEV'}</div>
              <div className="hidden sm:block">
                {isProduction ? 'PRODUCTION' : 'DEVELOPMENT'}
              </div>
            </div>
          )}
        </NavbarBrand>
        {user && (communityUUID || selectedCommunity) && (
          <ul className="hidden md:flex gap-4">
            {navigation.map((item) => {
              const href = item.tpl.replace(':uuid', selectedCommunity || communityUUID);
              const isActive = pathname === href;
              return (
                <NavbarItem isActive={isActive} key={item.name}>
                  <Link color="foreground" href={href}>
                    {item.name}
                  </Link>
                </NavbarItem>
              );
            })}
          </ul>
        )}
      </NavbarContent>

      <NavbarContent className="" as="div" justify="end">
        {/* <NavbarItem className="hidden md:flex">{user && searchInput}</NavbarItem> */}
        <div className="hidden sm:flex align-middle">
          <ThemeSwitcher />
        </div>
        <div className="hidden sm:flex align-middle">{user && settingsDropdown}</div>

        {user && communityDropdown}
      </NavbarContent>

      <NavbarMenu>
        {user && <NavbarMenuItem>{searchInput}</NavbarMenuItem>}
        <NavbarMenuItem className="mt-1">
          <ThemeSwitcher />
        </NavbarMenuItem>
        {user && selectedCommunity && (
          <div className="ml-1 flex flex-col gap-2">
            {navigation.map((item, index) => (
              <NavbarMenuItem key={`${item}-${index}`}>
                <Link
                  onClick={() => setIsMenuOpen(false)}
                  href={item.tpl.replace(':uuid', selectedCommunity)}
                >
                  {item.name}
                </Link>
              </NavbarMenuItem>
            ))}
          </div>
        )}
        {user && <NavbarMenuItem>{settingsDropdown}</NavbarMenuItem>}
      </NavbarMenu>
    </NextUINavbar>
  );
}
